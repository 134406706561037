export const ENVIRONMENT_DEFAULTS = {
  isDemoMode: false,
  debugRouting: false,
  isChildrenFeatureEnabled: false,
  isBadgeFeatureEnabled: false,
  barionPixelId: 'BPT-qI0wEKn225-F8',
  locales: [
    { lang: 'en_US', currency: 'USD', value: 'en', displayName: 'English', displayLong: 'English (United States)' },
    { lang: 'de_DE', currency: 'EUR', value: 'de', displayName: 'German', displayLong: 'German (Germany)' },
    { lang: 'fr_FR', currency: 'EUR', value: 'fr', displayName: 'French', displayLong: 'French (France)' },
  ],
  firebaseConfig: {
    enabled: false,
    debug: false,
    web: {
      apiKey: "AIzaSyDxYrAXQ3WGfZ0czIYIPsnKTHh3wa1L5CA",
      authDomain: "apparently-9441d.firebaseapp.com",
      projectId: "apparently-9441d",
      storageBucket: "apparently-9441d.firebasestorage.app",
      messagingSenderId: "39857923809",
      appId: "1:39857923809:web:81a53b33ff9901113ab9a0",
      measurementId: "G-HC0HY7ZW03",
    },
    android: {
      apiKey: "AIzaSyDf_Wltd5uIlcuEJX440WJdBPbb60MYbPw",
      authDomain: "apparently-9441d.firebaseapp.com",
      projectId: "apparently-9441d",
      storageBucket: "apparently-9441d.firebasestorage.app",
      messagingSenderId: "39857923809",
      appId: "1:39857923809:android:c9510c8070d21e483ab9a0",
      measurementId: "G-1111GP64N5",
    },
    ios: {
      apiKey: "AIzaSyCl73KFqC1Y3dVLf20HebDCBKvvXLzK4uA",
      authDomain: "apparently-9441d.firebaseapp.com",
      projectId: "apparently-9441d",
      storageBucket: "apparently-9441d.firebasestorage.app",
      messagingSenderId: "39857923809",
      appId: "1:39857923809:ios:ddf0d6c6cddbafe13ab9a0",
      measurementId: "G-GPWZD80LTF",
    },
  },
  cookieConsentV3Config: {
    revision: 3,
    mode: 'opt-in',
    autoShow: false,
    manageScriptTags: false,
    autoClearCookies: true,
    disablePageInteraction: false,
    hideFromBots: true,
    guiOptions: {
      consentModal: {
        layout: 'box',
        position: 'middle center',
        equalWeightButtons: false,
        flipButtons: false
      },
      preferencesModal: {
        layout: 'box',
        position: 'right',
        equalWeightButtons: false,
        flipButtons: false
      }
    },
    lazyHtmlGeneration: true,
    cookie: {
      useLocalStorage: true,
    },
    categories: {
      necessary: {
        enabled: true,
        readOnly: true,
      },

      analytics: {
        enabled: false,
        readOnly: false,
        autoClear: {
          cookies: [{
            name: /^(_ga|_gid|ba_)/,
            reloadPage: true,
          }],
        },
      },

      functional: {
        enabled: false,
        readOnly: false,
      },

      performance: {
        enabled: false,
        readOnly: false,
      },

      advertisement: {
        enabled: false,
        readOnly: false,
        autoClear: {
          cookies: [{
            name: /^(ba_)/,
            reloadPage: true,
          }],
        },
      }
    },
    language: { // https://cookieconsent.orestbida.com/advanced/language-configuration.html#external-translations
      default: 'hu',
      translations: {
        hu: async () => {
          const res = await fetch('./cookie-consent-v3-translations.json');
          return await res.json();
        }
      }
    }
  }
};
