import {
  inject,
  Injectable,
} from "@angular/core";
import { Performance } from '@angular/fire/performance';
import { FirebasePerformance } from '@capacitor-firebase/performance';
import { Capacitor } from "@capacitor/core";

@Injectable({
  providedIn: 'root',
})
export class PerformanceService {

  private readonly performance: Performance = inject(Performance);
  private readonly isNative !: boolean;

  constructor() {
    this.isNative = Capacitor.isNativePlatform();
  }

  async setDataCollectionEnabled(enabled: boolean): Promise<void> {
    if(this.isNative) {
      await FirebasePerformance.setEnabled({
        enabled: enabled,
      });
    }

    this.performance.dataCollectionEnabled = enabled;
  }

  setInstrumentationEnabled(enabled: boolean): void {
    this.performance.instrumentationEnabled = enabled;
  }

  setUserId(userId: any): void {
    // @todo
  }

}
