import {Injectable} from '@angular/core';
import {LocalstorageService} from "./localstorage.service";

@Injectable({
  providedIn: 'root',
})
export class JwtService {

  protected key = 'jwtToken';

  constructor(private localstorageService: LocalstorageService) {}

  getToken(): string {
    return this.localstorageService.getItem(this.key);
  }

  saveToken(token: string): void {
    this.localstorageService.setItem(this.key, token);
  }

  destroyToken(): void {
    this.localstorageService.removeItem(this.key);
  }
}
