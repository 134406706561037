import {
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {Provider} from "@angular/core";
import {StorageService} from "src/app/core/services/storage.service";
import { HttpTokenInterceptor } from './interceptors/index';
import { ApiService } from 'src/app/core/services/api.service';
import { AuthGuard } from 'src/app/core/services/auth-guard.service';
import { JwtService } from 'src/app/core/services/jwt.service';
import { UserService } from 'src/app/core/services/user.service';
import {NoNetInterceptorService} from "src/app/core/interceptors/no-net-interceptor.service";
//import {NetworkCheckService} from "src/app/core/services/network-check.service";
import {ToastService} from "src/app/core/services/toast-service.service";
import {SafePipe} from "src/app/core/pipes/htmlsanitizer.pipe";
import {AuthInterceptor} from "src/app/core/interceptors/auth.interceptor";
import { NotificationService } from './services/notification.service';
import {SettingsService} from "src/app/core/services/settings.service";

// @todo remove it!!!
import {FcmService} from "src/app/core/services/firebase-cloud-messaging.service";

const interceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: NoNetInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

export function provideCore(): Provider[] {
  return [
    ...interceptorProviders,
    ApiService,
    AuthGuard,
    NotificationService,  // @todo remove it!!!
    StorageService,
    JwtService,
    UserService,
    ToastService,
    //NetworkCheckService, // @todo remove it!!!
    FcmService, // @todo remove it!!!
    SafePipe,
    SettingsService,
  ];
}
