import {
  inject,
  Injectable,
} from "@angular/core";
import {
  Analytics,
  ConsentSettings,
  ScreenTrackingService,
  setAnalyticsCollectionEnabled,
  setConsent,
  setUserId,
} from "@angular/fire/analytics";
import {object} from "@angular/fire/database";
import {
  ConsentStatus,
  ConsentType,
  FirebaseAnalytics,
  SetConsentOptions,
} from "@capacitor-firebase/analytics";
import { Capacitor } from "@capacitor/core";

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {

  private readonly analytics: Analytics = inject(Analytics);
  private readonly screenTrackingService: ScreenTrackingService = inject(ScreenTrackingService);

  private readonly isNative !: boolean;

  constructor() {
    this.isNative = Capacitor.isNativePlatform();
  }

  async setAnalyticsCollectionEnabled(enabled: boolean): Promise<void> {
    if (this.isNative) {
      await FirebaseAnalytics.setEnabled({
        enabled: enabled,
      });
    }

    this.analytics.app.automaticDataCollectionEnabled = enabled;
    setAnalyticsCollectionEnabled(this.analytics, enabled);
  }

  async setUserId(userId: any): Promise<void> {
    if (this.isNative) {
      await FirebaseAnalytics.setUserId({
        userId: ! userId ? null : userId as string,
      });
    }

    setUserId(this.analytics, userId);
  }

  async setConsent(consentSettings: ConsentSettings): Promise<void> {
    if (this.isNative) {
      let nativeConsentSettings = this.consentToNativeConsent(consentSettings);

      for (const consentTypeSetting of nativeConsentSettings) {
        await FirebaseAnalytics.setConsent(consentTypeSetting);
      }
    }

    setConsent(consentSettings);
  }

  /**
   * @todo Missing consent type security_storage!!!
   * @see https://github.com/capawesome-team/capacitor-firebase/blob/9bdd458004d069f856cc9f007c9bb46a45f59afa/packages/analytics/src/web.ts#L34
   * */
  private consentToNativeConsent(consent: ConsentSettings): SetConsentOptions[] {
    return [
      { type: ConsentType.AdPersonalization, status: consent.ad_personalization === 'granted' ? ConsentStatus.Granted : ConsentStatus.Denied },
      { type: ConsentType.AdStorage, status: consent.ad_storage === 'granted' ? ConsentStatus.Granted : ConsentStatus.Denied },
      { type: ConsentType.AdUserData, status: consent.ad_user_data === 'granted' ? ConsentStatus.Granted : ConsentStatus.Denied },
      { type: ConsentType.AnalyticsStorage, status: consent.analytics_storage === 'granted' ? ConsentStatus.Granted : ConsentStatus.Denied },
      { type: ConsentType.FunctionalityStorage, status: consent.functionality_storage === 'granted' ? ConsentStatus.Granted : ConsentStatus.Denied },
      { type: ConsentType.PersonalizationStorage, status: consent.personalization_storage === 'granted' ? ConsentStatus.Granted : ConsentStatus.Denied },
    ];
  }

}
