import {Provider} from "@angular/core";
import {FullscreenOverlayContainer, OverlayContainer } from "@angular/cdk/overlay";
import {ActivityLogService} from "src/app/shared/services/activitylog.service";
import {ChallengeService} from "src/app/shared/services/chellenge.service";
import {NewsService} from "src/app/shared/services/news.service";
import {FavoriteService} from "src/app/shared/services/favorite.service";
import {BarionPixelService} from "src/app/shared/services/payment/barion/barion-pixel.service";

export function provideShared(): Provider[] {
  return [
    ActivityLogService,
    ChallengeService,
    NewsService,
    FavoriteService,
    BarionPixelService,
    {provide: OverlayContainer, useClass: FullscreenOverlayContainer},
  ];
}
