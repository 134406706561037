import { Injectable } from '@angular/core';
import {
  CrashOptions,
  FirebaseCrashlytics,
  LogOptions,
  RecordExceptionOptions,
  SetCustomKeyOptions,
} from "@capacitor-firebase/crashlytics";
import { Capacitor } from "@capacitor/core";

import * as StackTrace from 'stacktrace-js';

@Injectable({
  providedIn: 'root',
})
export class CrashlyticsService {

  private readonly isNative !: boolean;

  constructor() {
    this.isNative = Capacitor.isNativePlatform();
  }

  async setDataCollectionEnabled(enabled: boolean) {
    if( ! this.isNative) {
      return;
    }

    await FirebaseCrashlytics.setEnabled({
      enabled: enabled,
    });

    if(await this.isEnabled()) {
      await this.sendUnsentReports();
    }
  }

  async setUserId(userId: any): Promise<void> {
    if( ! this.isNative) {
      return;
    }

    await FirebaseCrashlytics.setUserId({
      userId: userId,
    });
  }

  async isEnabled() {
    if( ! this.isNative) {
      return false;
    }

    const { enabled } = await FirebaseCrashlytics.isEnabled();

    return enabled;
  }

  async log(options: LogOptions): Promise<void> {
    await FirebaseCrashlytics.log(options);
  }

  async crash(options: CrashOptions): Promise<void> {
    await FirebaseCrashlytics.crash(options);
  }

  async setCustomKey(options: SetCustomKeyOptions): Promise<void> {
    if( ! this.isNative) {
      return;
    }

    await FirebaseCrashlytics.setCustomKey(options);
  }

  async didCrashOnPreviousExecution(): Promise<boolean> {
    if( ! this.isNative) {
      return false;
    }

    const { crashed } = await FirebaseCrashlytics.didCrashOnPreviousExecution();
    return crashed;
  }

  async sendUnsentReports(): Promise<void> {
    if( ! this.isNative) {
      return;
    }

    await FirebaseCrashlytics.sendUnsentReports();
  }

  async deleteUnsentReports(): Promise<void> {
    if( ! this.isNative) {
      return;
    }

    await FirebaseCrashlytics.deleteUnsentReports();
  }

  async recordException(options: RecordExceptionOptions): Promise<void> {
    if( ! this.isNative) {
      return;
    }

    await FirebaseCrashlytics.recordException(options);
  }

  async recordExceptionWithStacktrace(error: Error): Promise<void> {
    if( ! this.isNative) {
      return;
    }

    const stacktrace = await StackTrace.fromError(error);
    await FirebaseCrashlytics.recordException({
      message: 'This is a non-fatal message.',
      stacktrace,
    });
  };

}
