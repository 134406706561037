import {
  inject,
  Injectable,
} from "@angular/core";
import {ApiService} from "src/app/core/services/api.service";
import {map} from "rxjs/operators";

@Injectable()
export class NewsService {

  private readonly apiService: ApiService = inject(ApiService);

  getNews() {
    return this.apiService
      .get<any>('/newsfeed')
      .pipe(map(data => {
        return data;
      }));
  }

}
