import {
  Injectable,
  inject,
} from "@angular/core";
import { DOCUMENT } from '@angular/common';
import { Platform } from '@ionic/angular/standalone';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root',
})
export class BarionPixelService {

  private document: Document = inject(DOCUMENT);
  private platform: Platform = inject(Platform);

  private scriptLoaded = false;

  enable(): void {
    if (this.platform.is('hybrid')) {
      return;
    }

    if (this.scriptLoaded) {
      // @ts-ignore
      window['bp']('init', 'grantConsent');
      return;
    }

    // Barion Pixel init
    // @ts-ignore
    window['bp'] = window['bp'] || function () {
      // @ts-ignore
      (window['bp'].q = window['bp'].q || []).push(arguments);
    };
    // @ts-ignore
    window['bp'].l = 1 * new Date();

    // Script inject
    const scriptElement = this.document.createElement('script');
    const firstScript = this.document.getElementsByTagName('script')[0];
    scriptElement.async = true;
    scriptElement.src = 'https://pixel.barion.com/bp.js';

    scriptElement.onload = () => {
      // @ts-ignore
      window['barion_pixel_id'] = environment.barionPixelId;

      // Pixel init
      // @ts-ignore
      window['bp']('init', 'addBarionPixelId', window['barion_pixel_id']);
      // @ts-ignore
      window['bp']('consent', 'grantConsent');
    };

    scriptElement.onerror = () => {
      console.error('Barion Pixel betöltése sikertelen.');
    };

    firstScript.parentNode?.insertBefore(scriptElement, firstScript);

    this.scriptLoaded = true;
  }

  disable(): void {
    if (this.platform.is('hybrid')) {
      return;
    }

    if ( ! this.scriptLoaded) {
      return;
    }

    // @ts-ignore
    window['bp']('consent', 'rejectConsent');
  }

  setEncryptedEmail(email: string): void {
    if ( ! this.scriptLoaded) {
      return;
    }

    // @ts-ignore
    window['bp']('identity', 'setEncryptedEmail', email);
  }

  setEncryptedPhone(phone: string): void {
    if ( ! this.scriptLoaded) {
      return;
    }

    // @ts-ignore
    window['bp']('identity', 'setEncryptedPhone', phone);
  }

  setUserId(userId: string|number): void {
    if ( ! this.scriptLoaded) {
      return;
    }

    this.setUserPropertiesProperties({
      userId: userId as string,
    });
  }

  setUserPropertiesProperties(setUserPropertiesProperties: object): void {
    if ( ! this.scriptLoaded) {
      return;
    }

    // @ts-ignore
    window['bp']('track', 'setUserPropertiesProperties', setUserPropertiesProperties);
  }

}
