import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {Title} from "@angular/platform-browser";
import {
  ActivationEnd,
  Router,
} from "@angular/router";
import {
  BehaviorSubject,
  filter,
} from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {

  private backNavigationLink$ = new BehaviorSubject<string|null>(null);
  private footerVisibility$ = new BehaviorSubject<boolean>(true);
  private isFooterCenterIconSelected$ = new BehaviorSubject<boolean>(false);
  private isFooterToSwiperMode$ = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private title: Title
  ) {
    this.router.events
      .pipe(
        takeUntilDestroyed(),
        filter((routerEvent) => routerEvent instanceof ActivationEnd)
      )
      .subscribe((routerEvent: ActivationEnd | any) => {
        const snapshot = routerEvent.snapshot;
        const data = snapshot.data;

        if ( ! data) {
          return;
        }

        const backNavigationRouterLink = data['backNavigationRouterLink'];

        if (backNavigationRouterLink || backNavigationRouterLink === null) {
          this.setBackNavigationLink(backNavigationRouterLink);
        }
      });
  }

  hideFooter(): void {
    this.footerVisibility$.next(false);
  }

  setFooterSwiperMode(value: boolean): void {
    this.isFooterToSwiperMode$.next(value);
  }

  showFooter(): void {
    this.footerVisibility$.next(true);
  }

  getFooterVisibility(): BehaviorSubject<boolean> {
    return this.footerVisibility$;
  }

  geFooterToSwiperMode(): BehaviorSubject<boolean> {
    return this.isFooterToSwiperMode$;
  }

  getIsFooterCenterIcoSelected(): BehaviorSubject<boolean> {
    return this.isFooterCenterIconSelected$;
  }

  setFooterCenterIconToSelected() {
    this.isFooterCenterIconSelected$.next(true);
  }

  setFooterCenterIconToDefault() {
    this.isFooterCenterIconSelected$.next(false);
  }

  getBackNavigationLink(): BehaviorSubject<string|null> {
    return this.backNavigationLink$;
  }

  setBackNavigationLink(link : string|null) {
    this.backNavigationLink$.next(link);
  }

  getTheTitle(): string {
    return this.title.getTitle();
  }

  setTheTitle(title: string) {
    const sitename : string = environment.application.name;

    if(title !== undefined) {
      this.title.setTitle(`${title} - ${sitename}`);
    }
  }

}
