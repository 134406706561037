import {
  inject,
  Injectable,
} from "@angular/core";
import {Profile} from "src/app/core/models";
import { ccBridgeService} from "src/app/shared/services/consent-manager/cc-bridge.service";
import {
  ConsentsByCategory,
  GoogleAnalyticsConsentSettings,
} from "src/app/shared/services/consent-manager/consent-manager-model";
import { PerformanceService } from "src/app/core/services/firebase/performance.service";
import { AnalyticsService } from "src/app/core/services/firebase/analytics.service";
import { CrashlyticsService } from "src/app/core/services/firebase/crashlytics.service";
import { BarionPixelService } from "src/app/shared/services/payment/barion/barion-pixel.service";
import { UserService } from "src/app/core/services/user.service";

@Injectable({
  providedIn: 'root',
})
export class ConsentManagerService {

  private readonly cc: ccBridgeService = inject(ccBridgeService);
  private readonly analyticsService: AnalyticsService = inject(AnalyticsService);
  private readonly crashlyticsService: CrashlyticsService = inject(CrashlyticsService);
  private readonly performanceService: PerformanceService = inject(PerformanceService);
  private readonly userService: UserService = inject(UserService);
  private readonly barionPixelService: BarionPixelService = inject(BarionPixelService);

  readonly defaultConsents: ConsentsByCategory = {
    necessary : 'granted',
    analytics : 'denied',
    advertisement : 'denied',
    functional : 'denied',
    performance : 'denied',
  };

  readonly defaultGAConsentSettings: GoogleAnalyticsConsentSettings = {
    ad_storage : 'denied',
    ad_user_data : 'denied',
    ad_personalization : 'denied',
    analytics_storage : 'denied',
    functionality_storage : 'denied',
    personalization_storage : 'denied',
    security_storage : 'granted',
  };

  private consents: ConsentsByCategory = this.defaultConsents;

  private gaConsentSettings: GoogleAnalyticsConsentSettings = this.defaultGAConsentSettings;

  async init(): Promise<void> {
    await this.analyticsService.setConsent(this.defaultGAConsentSettings);

    await this.cc.init();

    this.onConsentChange();

    window.addEventListener('cc:onConsent', () => {
      this.onConsentChange();
    });

    window.addEventListener('cc:onChange', () => {
      this.onConsentChange();
    });
  }

  showCCModalIfNeeded() {
    if ( ! this.cc.validConsent()) {
      this.cc.showModal();
    }
  }

  showPreferences() {
    this.cc.showPreferences();
  }

  getConsents(): ConsentsByCategory {
    return this.consents;
  }

  acceptedCategory(categoryName: keyof ConsentsByCategory): boolean {
    return this.consents[categoryName] === 'granted';
  }

  onConsentChange(): void {

    this.updateConsents();
    this.updateGAConsentSettings();

    // send ga consent signal
    this.analyticsService.setConsent(this.getGAConsentSettings());

    // anonym data collection can be enabled always but ofc only on native platforms it does anything...
    this.crashlyticsService.setDataCollectionEnabled(true);

    let userId = localStorage.getItem('userId');

    if (this.acceptedCategory('performance')) {
      // enable performance data collection
      this.performanceService.setInstrumentationEnabled(true);
      this.performanceService.setDataCollectionEnabled(true);
    } else {
      // disable performance data collection
      this.performanceService.setInstrumentationEnabled(false);
      this.performanceService.setDataCollectionEnabled(false);
    }

    if (this.acceptedCategory('analytics')) {
      // enable general analytics data collection
      this.analyticsService.setAnalyticsCollectionEnabled(true);

      // enable barion pixel or its data collection
      if (this.acceptedCategory('advertisement')) {
        this.barionPixelService.enable();

        if (userId) {
          this.barionPixelService.setUserId(userId);

          this.userService.getProfile().subscribe(resp => {
            const profile: Profile = resp.data;

            // set user data for barion
            this.barionPixelService.setEncryptedEmail(profile.email);

            if (profile.phone) {
              this.barionPixelService.setEncryptedPhone(profile.phone);
            }
          });
        }


      }

      if (this.acceptedCategory('performance')) {
        // @todo enable firebase crashlytics analytics integration
        // @todo enable firebase performance analytics integration
      } else {
        // @todo disable firebase crashlytics analytics integration
        // @todo disable firebase performance analytics integration
      }
    } else {
      // disable general analytics data collection
      this.analyticsService.setAnalyticsCollectionEnabled(false);

      // disable barion pixel data collection
      this.barionPixelService.disable();
    }

    if (this.acceptedCategory('functional')) {

      // set user id on firebase crashlytics
      if(userId) {
        this.crashlyticsService.setUserId(userId);
      } else {
        this.crashlyticsService.setUserId(null);
      }

      if (this.acceptedCategory('analytics')) {
        // set user id on firebase analytics
        if(userId) {
          this.analyticsService.setUserId(userId);
        } else {
          this.analyticsService.setUserId(null);
        }
      } else {
        // set user id to null firebase analytics to anonomize
        this.analyticsService.setUserId(null);
      }

      if (this.acceptedCategory('performance')) {
        if(userId) {
          // set user id on firebase performance
          this.performanceService.setUserId(userId);
        } else {
          this.performanceService.setUserId(null);
        }
      } else {
        // set user id to null on firebase performance
        this.performanceService.setUserId(null);
      }
    }

    if (this.acceptedCategory('advertisement')) {
      if(userId) {
        // @todo enable ads data collection
      }
    } else {
      // @todo disable ads data collection
    }

  }

  getGAConsentSettings(): GoogleAnalyticsConsentSettings {
    return this.gaConsentSettings;
  }

  private updateGAConsentSettings() {
    this.gaConsentSettings = {
      ad_storage: this.acceptedCategory('advertisement') ? 'granted' : 'denied',
      ad_user_data: this.acceptedCategory('advertisement') ? 'granted' : 'denied',
      ad_personalization: this.acceptedCategory('advertisement') ? 'granted' : 'denied',
      analytics_storage: this.acceptedCategory('analytics') ? 'granted' : 'denied',
      functionality_storage: this.acceptedCategory('functional') ? 'granted' : 'denied',
      personalization_storage: this.acceptedCategory('functional') ? 'granted' : 'denied',
      security_storage : 'granted',
    };
  }

  private updateConsents(): this {
    this.consents = this.cc.getConsents();

    return this;
  }

}
