import {
  Injectable,
} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {
  RouterStateSnapshot,
  TitleStrategy,
} from "@angular/router";
import {environment} from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class TemplatePageTitleStrategy extends TitleStrategy {

  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    const sitename : string = environment.application.name;

    if(title !== undefined) {
      this.title.setTitle(`${title} - ${sitename}`);
    }
  }
}
