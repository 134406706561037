import {ENVIRONMENT_DEFAULTS} from 'src/environments/environment-defaults';

import 'zone.js/plugins/zone-error';

export const environment = {
  ...ENVIRONMENT_DEFAULTS,
  production: false,
  debugRouting: false,
  application: {
    name: 'apparently',
    version: '2.1.0',
    domain: 'app.apparently.hungaropixel.hu',
    protocol: 'https',
  },
  api_url: 'https://admin.apparently.hungaropixel.hu/api',
  backendBaseUrl: 'https://admin.apparently.hungaropixel.hu',
  ws: 'ws://admin.apparently.hungaropixel.hu:1080/rooms',
  defaultChatRoomId: 1,
  isWebsocketProject: false
};
