import {Routes} from "@angular/router";

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/pages/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    title: 'Hitelesítés',
    loadChildren: () => import('./core/modules/auth/auth.routes').then( r => r.AUTH_ROUTES),
  },
  {
    path: 'pages',
    title: 'Bejelentkezéshez kötött',
    loadChildren: () => import('./pages/pages.routes').then( r => r.PAGES_ROUTES),
  },
  { path: '**', redirectTo: '/pages/dashboard' },
];
