import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {Platform} from "@ionic/angular/standalone";

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  public isIOS;
  private lastUpdated: number = 0;
  private setting: any;

  constructor(
    private apiService: ApiService,
    private platform: Platform
  ) {
    this.isIOS = this.platform.is('ios') && this.platform.is('hybrid');
    this.reloadSettings();
  }

  public reloadSettings(): void {
    this.getSettings().subscribe();
  }

  public getSettings(): Observable<any> {
    if (this.isSettingsExpired() && this.setting) {
      return of(this.setting);
    }

    return this.apiService.get('/app/app_settings').pipe(
      map((res: any) => {
        this.setting = res;
        this.lastUpdated = Date.now() / 1000; // ms to sec

        return res;
      })
    );
  }

  public getIsBarionEnabled(): Observable<boolean> {
    return this.getSettings().pipe(
      map((settings: any) => {
        return (
          settings.payment_pg_status === '1' &&
          settings.payment_barion_pg_status === '1' &&
          this.isIOS === false
        );
      })
    );
  }

  public getIsPaymentEnabled(): Observable<boolean> {
    return this.getSettings().pipe(
      map((settings: any) => {
        return settings.payment_pg_status === '1';
      })
    );
  }

  private isSettingsExpired(): boolean {
    // last updated + 1 hr
    const validUntil = this.lastUpdated + 3600;

    return this.lastUpdated <= validUntil;
  }

}
