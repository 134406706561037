import {
  Injectable,
  NgZone,
} from "@angular/core";
import { Router } from '@angular/router';
import {
  Capacitor,
} from "@capacitor/core";
import { ApiService } from "./api.service";
import * as CapDevice from '@capacitor/device';
import {ToastService} from "./toast-service.service";
import {
  PushNotifications,
  ActionPerformed,
  PushNotificationSchema,
  Token,
} from "@capacitor/push-notifications";
import {NotificationService} from "./notification.service";

@Injectable()
export class FcmService {

  uniqueDeviceId:string | null = null;


  constructor(
    private router: Router,
    private zone: NgZone,
    private toastManager: ToastService,
    private apiService: ApiService,
    private notificationService: NotificationService
  ) { }

 async initPush() {
      /*
     this.afAuth.signInAnonymously().then(() => {
         this.afMessaging.getToken.subscribe((token) => {
             console.log('Got FCM token: ', token);
             alert(token);

             this.addPushListeners();
         });
     });
*/
     await this.addPushListeners();
  }

  private async addPushListeners() {
      const isNative = Capacitor.isNativePlatform();

      if (isNative) {
          const deviceId: CapDevice.DeviceId = await CapDevice.Device.getId();
          // @ts-ignore

          this.uniqueDeviceId = deviceId.identifier;
          await this.registerPush();
      } else {
          // this.initPushForWeb();
      }
  }

  private async registerPush() {
      // @ts-ignore
    await PushNotifications.addListener(
      'registration',
      async (token: Token) => {
        const _token = token.value;

        if (_token) {
          const profileResp = await this.apiService.get('/users/profile',{token: _token, device: this.uniqueDeviceId}).toPromise() as any;
          const platform = Capacitor.getPlatform();

          if (profileResp) {
            const payload = {
              ...profileResp.data,
              'firebase_token': _token,
              'device_platform': platform,
            }

            // save firebase token
            this.apiService.put('/users/profile', payload).toPromise().then((res) => {});
          }
        }
       }
    );

    // @ts-ignore
    await PushNotifications.addListener('registrationError', async (error: any) => {
      console.error('Push notification device registration error: ' + JSON.stringify(error));
    });

    // @ts-ignore
    await PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        if(notification.title && notification.title.length > 0) {
          await this.toastManager.openToast(notification.title);
        }
        setTimeout(() => {
          this.zone.run(() => {
            this.notificationService.notificationRecivedEmitter.emit();
          });
        }, 1000);
      }
    );

      // @ts-ignore
    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        const data = notification.notification.data;

        setTimeout(() => {
          this.zone.run(() => {
            this.notificationService.notificationRecivedEmitter.emit();
          });
        }, 1000);
        setTimeout(() => {
          this.zone.run(() => {
            if (data && data.route) {
              this.router.navigateByUrl(data.route);

              return;
            }
            this.router.navigateByUrl(`/pages/notifications`);
          });
        }, 600);
      }
    );


    // @ts-ignore
    await PushNotifications.requestPermissions().then(async (permission) => {
      if (permission.receive) {
        // Register with Apple / Google to receive push via APNS/FCM
        // @ts-ignore
        await PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });



  }
}
