import {
  AfterViewInit,
  Component,
  isDevMode,
  NgZone,
  //VERSION,
} from "@angular/core";
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import {
  IonApp,
  IonRouterOutlet,
  NavController,
  Platform,
} from "@ionic/angular/standalone";
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from "@angular/router";
import { ConsentManagerService } from "src/app/shared/services/consent-manager/consent-manager.service";
//import {FcmService} from "src/app/core/services/firebase-cloud-messaging.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    IonApp,
    IonRouterOutlet,
  ],
})
export class AppComponent implements AfterViewInit {

  //name = 'Angular ' + VERSION.full;

  constructor(
    private readonly zone: NgZone,
    private readonly consentManager: ConsentManagerService,
    private readonly platform: Platform,
    private readonly router: Router,
    private readonly navCtrl: NavController,
    //private readonly fcmService: FcmService,
  ) {
    this.withRoutesDebugger();

    this.handleUrlOpen();
    this.platform.ready().then(async () => {
      await SplashScreen.hide();

      if(this.platform.is('hybrid')) {
        await StatusBar.hide();
      }

      try {
        //this.fcmService.initPush();
      } catch (e) {

      }
    });
  }

  async ngAfterViewInit() {
    await this.consentManager.init();

    this.consentManager.showCCModalIfNeeded();
  }

  private async handleUrlOpen() {
    await App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.runOutsideAngular(() => {
        const domain = environment.application.domain;
        const pathArray = event.url.split(domain);
        // The pathArray is now like ['https://domain.com', '/details/42']

        // Get the last element with pop()
        const appPath = pathArray.pop();

        if (appPath) {
          this.navCtrl.navigateRoot(appPath);
        }
      });
    });
  }

  private withRoutesDebugger(): void {
    if( ! isDevMode() || ! environment.debugRouting) {
      return;
    }

    import('src/app/core/routes-debugger').then(m => {
      m.logRouterRoutesChange(this.router);
    });
  }

}
