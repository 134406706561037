import {
  Injectable,
  isDevMode,
} from "@angular/core";
import {
  ConsentsByCategory,
  onConsentChangeHandler,
} from "src/app/shared/services/consent-manager/consent-manager-model";
import {environment} from "src/environments/environment";
import * as CookieConsent from "vanilla-cookieconsent";

// @todo https://cookieconsent.orestbida.com/advanced/consent-logging.html
function logConsent(cookie ?: CookieConsent.CookieValue): void {
  // Retrieve all the fields
  const preferences = CookieConsent.getUserPreferences();

  if (isDevMode()) {
    console.info(cookie, preferences);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ccBridgeService {

  private onChangeHandler: onConsentChangeHandler = () => {};

  async init(): Promise<void> {
    return await CookieConsent.run(this.getConfig());
  }

  getDefaultConfig(): Partial<CookieConsent.CookieConsentConfig> {
    return environment.cookieConsentV3Config as unknown as Partial<CookieConsent.CookieConsentConfig>;
  }

  getConfig(): CookieConsent.CookieConsentConfig {
    let customCookieConsentConfig: Partial<CookieConsent.CookieConsentConfig> = {
      onFirstConsent: this.onFirstConsent,
      onConsent: this.onConsent,
      onChange: this.onChange,
    } as unknown as Partial<CookieConsent.CookieConsentConfig>;

    return {
      ...this.getDefaultConfig(),
      ...customCookieConsentConfig,
    } as CookieConsent.CookieConsentConfig;
  }

  getConsents(): ConsentsByCategory {
    return {
      necessary : CookieConsent.acceptedCategory('necessary') ? 'granted' : 'denied',
      analytics : CookieConsent.acceptedCategory('analytics') ? 'granted' : 'denied',
      advertisement : CookieConsent.acceptedCategory('advertisement') ? 'granted' : 'denied',
      functional : CookieConsent.acceptedCategory('functional') ? 'granted' : 'denied',
      performance : CookieConsent.acceptedCategory('performance') ? 'granted' : 'denied',
    };
  }

  // This event is triggered only the very first time that the user expresses their choice of consent (accept/reject).
  onFirstConsent(param: {
    cookie: CookieConsent.CookieValue
  }): void {
    logConsent(param.cookie);
  }

  // This event is triggered the very first time the user expresses their choice of consent — just like onFirstConsent — but also on every subsequent page load.
  onConsent(param: {
    cookie: CookieConsent.CookieValue
  }): void {
    logConsent(param.cookie);
  }

  // This event is triggered when the user modifies their preferences and only if consent has already been provided.
  onChange(
    cookie: CookieConsent.CookieValue,
    changedCategories: string[],
    changedServices: { [ key: string ]: string[] }
  ): void {
    logConsent(cookie);
  }

  showModal(): this {
    CookieConsent.show(true);

    return this;
  }

  showPreferences(): this {
    CookieConsent.showPreferences();

    return this;
  }

  acceptedCategory(categoryName: keyof ConsentsByCategory): boolean {
    return CookieConsent.acceptedCategory(categoryName);
  }

  reset(): this {
    CookieConsent.reset(true);

    return this;
  }

  validConsent(): boolean {
    return CookieConsent.validConsent();
  }

}
