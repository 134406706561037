import {ApplicationConfig} from "@angular/core";
import {isDevMode} from "@angular/core";
import {
  getAnalytics,
  provideAnalytics,
  ScreenTrackingService,
} from "@angular/fire/analytics";
import {
  APP_NAME,
  APP_VERSION,
  COLLECTION_ENABLED,
  CONFIG,
  DEBUG_MODE,
} from "@angular/fire/compat/analytics";
import {
  DATA_COLLECTION_ENABLED,
  INSTRUMENTATION_ENABLED,
  PerformanceMonitoringService,
} from "@angular/fire/compat/performance";
import {providePerformance} from "@angular/fire/performance";
import {
  PreloadAllModules,
  provideRouter,
  withComponentInputBinding,
  withDebugTracing,
  withEnabledBlockingInitialNavigation,
  withPreloading,
  withRouterConfig,
  RouteReuseStrategy,
  TitleStrategy,
  RouterFeature,
} from "@angular/router";
import {
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from "@angular/common/http";
import {provideIonicAngular, IonicRouteStrategy} from "@ionic/angular/standalone";
import {Capacitor} from "@capacitor/core";
import {provideAnimations} from "@angular/platform-browser/animations";
import {environment} from "src/environments/environment";
import {
  FirebaseOptions,
  initializeApp,
  provideFirebaseApp,
} from "@angular/fire/app";
import {APP_ROUTES} from "src/app/app.routing";
import {provideCore} from 'src/app/core/core';
import {BACKEND_URL} from "src/app/core/core.injectors";
import {provideShared} from "src/app/shared/shared";
import {providePages} from "src/app/pages/pages";
import { getPerformance } from "@angular/fire/performance";
import {TemplatePageTitleStrategy} from "src/app/template-page-title-strategy";

const IS_NATIVE_PLATFORM: boolean = Capacitor.isNativePlatform();
const PLATFORM: string = Capacitor.getPlatform();
const FIREBASE_ANALYTICS_ENABLED_VALUE: boolean = environment.firebaseConfig.enabled;
let FIREBASE_CONFIG_VALUE: FirebaseOptions = environment.firebaseConfig.web;

if(IS_NATIVE_PLATFORM) {
  if(PLATFORM === 'ios') {
    FIREBASE_CONFIG_VALUE = environment.firebaseConfig.ios;
  } else if(PLATFORM === 'android') {
    FIREBASE_CONFIG_VALUE = environment.firebaseConfig.android;
  }
}

const FIREBASE_ANALYTICS_CONFIG_VALUE: object = {
  send_page_view: FIREBASE_ANALYTICS_ENABLED_VALUE,
  allow_google_signals: FIREBASE_ANALYTICS_ENABLED_VALUE,
  allow_ad_personalization_signals: FIREBASE_ANALYTICS_ENABLED_VALUE,
  anonymize_ip: FIREBASE_ANALYTICS_ENABLED_VALUE,
};

let routerFeatures: RouterFeature<any>[] = [
  withPreloading(PreloadAllModules),
  withComponentInputBinding(),
  withEnabledBlockingInitialNavigation(),
];

if(isDevMode() && environment.debugRouting) {
  routerFeatures.push(withDebugTracing());
}

export const appConfig: ApplicationConfig = {
  providers: [

    { provide: BACKEND_URL, useValue: environment.api_url },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: TitleStrategy, useClass: TemplatePageTitleStrategy },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorHandlingInterceptor,
    //   multi: true,
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CommonHttpInterceptor,
    //   multi: true,
    // },
    ScreenTrackingService,
    PerformanceMonitoringService,
    { provide: INSTRUMENTATION_ENABLED, useValue: FIREBASE_ANALYTICS_ENABLED_VALUE },
    { provide: DATA_COLLECTION_ENABLED, useValue: FIREBASE_ANALYTICS_ENABLED_VALUE },
    { provide: CONFIG, useValue: FIREBASE_ANALYTICS_CONFIG_VALUE },
    { provide: COLLECTION_ENABLED, useValue: FIREBASE_ANALYTICS_ENABLED_VALUE },
    { provide: DEBUG_MODE, useFactory: (() => isDevMode() || environment.firebaseConfig.debug) },
    { provide: APP_NAME, useValue: environment.application.name },
    { provide: APP_VERSION, useValue: environment.application.version || '2.1.0' },

    provideFirebaseApp(() => initializeApp(FIREBASE_CONFIG_VALUE)),
    provideAnalytics(() => getAnalytics()),
    providePerformance(() => getPerformance()),

    provideCore(),
    provideShared(),
    providePages(),

    provideIonicAngular({
      rippleEffect: false,
      mode: 'md',
      // animated: true,
    }),

    provideRouter(APP_ROUTES,
      ...routerFeatures,
      withRouterConfig({
        //canceledNavigationResolution?: "replace" | "computed",
        onSameUrlNavigation: 'ignore',
        paramsInheritanceStrategy: 'always',
        //urlUpdateStrategy?: "eager" | "deferred",
        //resolveNavigationPromiseOnError?: boolean

     })
    ),

    provideHttpClient(
      withFetch(),
      withInterceptorsFromDi(),
    ),

    provideAnimations(),
  ],

};
