import { Injectable } from '@angular/core';
import {ApiService} from "src/app/core/services/api.service";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class ChallengeService {

  constructor(
    private apiService: ApiService,
  ) { }

  getCourses(searchQuery = '', isWithLessions = 0, hasLessons = 0) {
    const urlPart = `/courses?filters[q]=${searchQuery}&filters[has_lessons]=${hasLessons}&filters[with_lessons]=${isWithLessions}`;

    return this.getRequest(urlPart);
  }
  getCoursesWithCategories(searchQuery = '', isWithLessions = 0, hasLessons = 0) {
    const urlPart = `/courses/categories`;

    return this.getRequest(urlPart);
  }
  getActiveCourses() {
    const urlPart = `/courses?filters[progress_status]=active`;
    return this.getRequest(urlPart);
  }

  getActivePractiseSessionsResults() {
    const urlPart = '/elearning/dashboard_active_practise_sessions/active_practise_session_quizes_results';
    return this.getRequest(urlPart);
  }

  getFree() {
    const urlPart = `/courses?filters[is_paywalled]=0`;

    return this.getRequest(urlPart);
  }

  getCoursesBySearchQuery(q: string) {
    const urlPart = '/courses?filters[q]=' + q;

    return this.getRequest(urlPart);
  }

  getCourseById(id: number, withPractiseSessions: boolean = true, withLessons: boolean = false) {
    let urlPart = '/courses/' + id;

    if (withLessons || withPractiseSessions) {
      urlPart += '?include=';
    }

    if (withLessons) {
      urlPart += 'lessons';
    }

    if (withPractiseSessions) {
      if (withLessons) {
        urlPart += ',';
      }

      urlPart += 'practise_sessions';
    }

    return this.getRequest(urlPart);
  }

  getLessonsByCourseId(courseId: number) {
    const urlPart = '/lessons' + '?include=quizes&filters[course_id]=' + courseId;

    return this.getRequest(urlPart);
  }

  getCourseAttachmentsByCourseId(courseId: number) {
    const urlPart = '/courses/attachments?filters[course_id]=' + courseId;

    return this.getRequest(urlPart);
  }

  getPracticeSessionWithQuizes() {
    const urlPart = '/practise_sessions?include=quizes';

    return this.getRequest(urlPart);
  }
  getPracticeSessionWithQuizesByCourseId(courseId: number | string) {
    const urlPart = '/practise_sessions?include=quizes,tips&filters[course_id]=' + courseId;

    return this.getRequest(urlPart);
  }
  getChallangeWithQuizes() {
    const urlPart = '/lessons?include=quizes';

    return this.getRequest(urlPart);
  }

  getAllTips(): Observable<any> {
    const urlPart = '/practise_sessions/tips';

    return this.getRequest(urlPart);
  }

  private getRequest(urlPart: string) {
    return this.apiService
      .get<any>(urlPart)
      .pipe(map(data => {
        return data;
      }));
  }
}
