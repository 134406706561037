import {
  EventEmitter,
  inject,
  Injectable,
} from "@angular/core";
import {ApiService} from "./api.service";
import {map} from "rxjs/operators";

@Injectable()
export class NotificationService {

  notificationRecivedEmitter = new EventEmitter<any>();

  private readonly apiService: ApiService = inject(ApiService);

  getNotifications() {
    return this.apiService
      .get<any>('/reminders')
      .pipe(map(data => {
        return data;
      }));
  }
}
